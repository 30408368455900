<template>
  <statements></statements>
</template>

<script>
import Statements from '../merchant/Statements.vue'
export default {
  components: {
    Statements
  }
}
</script>