<template>
  <shipblu-prompt
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="bankDepositModal"
    :titleIcon="'CreditCardIcon'"
    :title="$t('Transfer Details')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="vx-row mb-base" v-for="bankDeposit in bankDeposits" :key="bankDeposit.index">
      <div class="vx-col w-full mt-2">
        <label class="font-medium text-sm text-darkgray">{{$t('Bank Reference Number')}}</label>
        <div class="py-2 px-4 bank-deposits">
          <p class="text-primary text-base font-semibold">{{bankDeposit.bank_reference_number}}</p>
        </div>
      </div>
      <div class="vx-col w-1/2 mt-2">
        <label class="font-medium text-sm text-darkgray">{{$t('Amount')}}</label>
        <div class="py-2 px-4 bank-deposits">
          <p class="text-primary text-base font-semibold">
            {{bankDeposit.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} <span class="font-semibold text-darkgray text-sm">{{$t('EGP')}}</span>
          </p>
        </div>
      </div>
      <div class="vx-col w-1/2 mt-2">
        <label class="font-medium text-sm text-darkgray">{{$t('Deposit Date')}}</label>
        <div class="py-2 px-4 bank-deposits">
          <p class="text-primary text-base font-semibold">{{bankDeposit.deposit_date}}</p>
        </div>
      </div>
      <div class="vx-col w-1/2 mt-2">
        <label class="font-medium text-sm text-darkgray">{{$t('Status')}}</label>
        <div class="py-2 px-4 bank-deposits">
          <p class="text-primary text-base font-semibold capitalize">{{bankDeposit.status}}</p>
        </div>
      </div>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['bankDepositModal', 'bankDeposits'],
  data () {
    return {
    }
  },
  components: {
    ShipbluPrompt
  },
  methods: {
    closeModal () {
      this.$emit('bankDepositModal', false)
    }
  }
}
</script>
<style>
.bank-deposits{
  background: #F0F8FF;
  border-radius: 5px;
}
</style>